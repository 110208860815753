import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,_vm._b({attrs:{"id":"core-navigation-drawer","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"expand-on-hover":_vm.expandOnHover,"right":_vm.$vuetify.rtl,"src":_vm.barImage,"mobile-break-point":"960","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c(VImg,_vm._b({attrs:{"gradient":("to bottom, " + _vm.barColor)}},'v-img',props,false))]}},{key:"append",fn:function(){return [_c('base-item',{attrs:{"item":{
        title: 'v2.IT BPDPKS@2020',
        icon: 'mdi-security-home'
      }}})]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c(VDivider,{staticClass:"mb-1"}),_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItem,[_c(VListItemAvatar,{staticClass:"align-self-center",attrs:{"color":"white","contain":""}},[_c(VImg,{attrs:{"src":_vm.logo_bpdp,"max-height":"40"}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"display-1",domProps:{"textContent":_vm._s(_vm.profile.title)}})],1)],1)],1),_c(VDivider,{staticClass:"mb-2"}),_c(VList,{attrs:{"expand":"","nav":""}},[_c('div'),_vm._l((_vm.computedItems),function(item,i){return [(i==1)?_c(VDivider,{key:i,staticClass:"mb-2"}):_vm._e(),(item.children)?_c('base-item-group',{key:("group-" + i),attrs:{"item":item}}):_c('base-item',{key:("item-" + i),attrs:{"item":item}})]}),_c('div')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }